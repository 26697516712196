<template>
  <div class="chat">
    问答区
  </div>
</template>

<script>
export default {
name: "Answer"
}
</script>

<style scoped>
.chat{
  background-color: #fff;
  min-height: calc(100vh - 120px);
  padding: 20px;
}
</style>
